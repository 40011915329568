/**
 * @description event emitter
 * @author liuqh
 */

import ee from 'eventemitter3'

const emitter = new ee()

export default emitter
